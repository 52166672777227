<template>
  <div class="lidim">
      <div class="lidim-wrapper">
        <div class="lidim-wrapper-header">
            <h2>לידים לטיפול</h2>
        </div>
        <div v-if="role>=99" class="lidim-wrapper-tools">
            <el-button style="margin:0;" @click="show_new_lid=true" type="success">חדש+</el-button>
            <el-button @click="state='Agents'" style="margin-right:5px; margin-left:0;" type="primary">סוכנים</el-button>
            <el-button @click="state='LidimManager'" style="margin-right:5px;" type="warning">ניהול</el-button>
        </div>
          
        <div v-if="!state && platform=='desktop'"  class="lidim-wrapper-content">
            <div class="lidim-wrapper-content-filter-bar">
            <el-input style="width:30%" v-model="search" placeholder="חיפוש" />
            <el-select style="margin-right:5px;" v-model="filter_by" class="m-2" placeholder="סנן לפי" size="large">
                <el-option
                    v-for="option in lids_status_options"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                />
            </el-select>
            </div>
            <div class="lidim-wrapper-content-table">
                <table>
                    <tr>
                        <th style="cursor:pointer;" @click="handleSortTable('תאריך', !selectedSorted.order)">תאריך</th>
                        <th> הלקוח/עסק</th>
                        <th> המנהל</th>
                        <th>כתובת</th>
                        <th>עיר</th>
                        <th>טלפון</th>
                        <th>הערות</th>
                        <th style="cursor:pointer;" @click="handleSortTable('סוכן', !selectedSorted.order)"> סוכן</th>
                        <th style="cursor:pointer;" @click="handleSortTable('סטטוס', !selectedSorted.order)">סטטוס</th>
                        <th>פעולות</th>
                    </tr>
                    <h1 v-if="lidim.length==0">לא קיימים לידים חדשים</h1>
                    <template v-for="lid in filter_by_status" :key="lid.uid">
                        <tr>
                            <td v-if="!lid.created_at.seconds">{{new Date(lid.created_at).toLocaleDateString('he')}}</td>
                            <td v-else>{{new Date(lid.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                            <td>{{lid.business_name}}</td>
                            <td>{{lid.manager_name}}</td>
                            <td>{{lid.address}}</td>
                            <td>{{lid.city}}</td>
                            <td>{{lid.phone}}</td>
                            <td v-if="lid.notes">{{lid.notes}}</td>
                            <td v-else>לא צויינו הערות</td>
                            <td>{{lid.agent_name?lid.agent_name:''}}</td>
                            <td v-if="lid.status=='pending'" style="color:#f5953b;">
                                <p>ממתין לאישור</p>
                                <small style="color:#333;">{{lid.pending_string}}</small>
                            </td>
                            <td v-if="lid.status=='open'" style="color:green;">פתוח</td>
                            <td>
                                <div class="options">
                                    <el-button style="width:49%; margin:0;" v-if="lid.status=='pending'" @click="handle_close_lid(lid)" type="success">אישור</el-button>
                                    <el-button style="width:49%; margin:0;"  v-if="lid.status=='open'" @click="handle_open_lid(lid)" type="primary"> טיפול</el-button>
                                    <el-button style="width:49%; margin:0; margin-right:40px;"  @click="handle_cancele_lid(lid)" type="danger">ביטול</el-button>
                                </div>
                            </td>
                        </tr>
                    </template> 
                </table>
            </div>
        </div>
        <div v-if="!state && platform!='desktop'"  class="lidim-wrapper-content">
            <div class="lidim-wrapper-content-filter-bar">
                <el-input style="width:30%" v-model="search" placeholder="חיפוש" />
                <el-select style="margin-right:5px;" v-model="filter_by" class="m-2" placeholder="סנן לפי" size="large">
                    <el-option
                        v-for="option in lids_status_options"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                    />
                </el-select>
            </div>
             <div class="lidim-wrapper-content-table">
                <h1 v-if="lidim.length==0">לא קיימים לידים חדשים</h1>
                <template  v-for="lid in filter_by_status" :key="lid.uid">
                    <table class="mobile-table">
                        <tr>
                            <th style="width:30%;">תאריך</th>
                            <td v-if="!lid.created_at.seconds">{{new Date(lid.created_at).toLocaleDateString('he')}}</td>
                            <td v-else>{{new Date(lid.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                        </tr>
                        <tr>
                            <th>הלקוח/עסק</th>
                            <td>{{lid.business_name}}</td>
                        </tr>
                        <tr>
                            <th> המנהל</th>
                            <td>{{lid.manager_name}}</td>
                        </tr>
                        <tr>
                            <th>כתובת</th>
                            <td>{{lid.address}}</td>
                        </tr>
                        <tr>
                            <th>עיר</th>
                            <td>{{lid.city}}</td>
                        </tr>
                        <tr>
                            <th>טלפון</th>
                            <td>{{lid.phone}}</td>
                        </tr>
                        <tr>
                            <th>הערות</th>
                            <td v-if="lid.notes">{{lid.notes}}</td>
                            <td v-else>לא צויינו הערות</td>
                        </tr>
                        <tr>
                            <th> סוכן</th>
                            <td>{{lid.agent_name?lid.agent_name:''}}</td>
                        </tr>
                        <tr>
                            <th>סטטוס</th>
                            <td v-if="lid.status=='pending'" style="color:#f5953b;">
                                <p>ממתין לאישור</p>
                                <small style="color:#333;">{{lid.pending_string}}</small>
                            </td>
                            <td v-if="lid.status=='open'" style="color:green;">פתוח</td>
                        </tr>
                        <tr>
                            <th>פעולות</th>
                                <td>
                                <div class="options">
                                    <el-button style="width:49%; margin:0;" v-if="lid.status=='pending'" @click="handle_close_lid(lid)" type="success">אישור</el-button>
                                    <el-button style="width:49%; margin:0;"  v-if="lid.status=='open'" @click="handle_open_lid(lid)" type="primary"> טיפול</el-button>
                                    <el-button style="width:49%; margin:0; margin-right:40px;"  @click="handle_cancele_lid(lid)" type="danger">ביטול</el-button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </template>
             </div>
        </div>
        <div v-if="state" class="lidim-wrapper-content">
            <component @create_new_lid="handle_create_lid_with_previous_data" :is="state" @close="state=null"
             @createLid="handle_create_lid" @delete="handle_delete_lid"/>
        </div>
      </div>
        <NewLid :new_lid="new_lid" :agent="agent" @newLid="handle_new_lid" v-if="show_new_lid"
         @close="show_new_lid = false; new_lid = null " />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import NewLid from '../../components/Agent_portal/Lidim/NewLid.vue'
import Agents from '../../components/Agent_portal/Lidim/Agents.vue'
import LidimManager from '../../components/Agent_portal/Lidim/LidimManager.vue'
import { projectFirestore } from '../../firebase/config'
import Swal from 'sweetalert2'
import { computed } from '@vue/runtime-core'
import store from '../../store'
export default {
    components:{NewLid,Agents,LidimManager},
    setup(){
        const lids_status_options = ref([
            {
                value:'all',
                label:'הצג הכל'
            },
            {
                value:'open',
                label:'לידים פתוחים'
            },
            {
                value:'pending',
                label:'לידים ממתינים'
            },
        ])
        const filter_by = ref('all')
        const filter_by_status = ref(computed(()=>{
            if(filter_by.value == 'all'){
                return sorted_lids.value
            }
            return sorted_lids.value.filter(lid=>lid.status==filter_by.value)
        }))
        const search = ref('')
        const state = ref(null)
        const role = ref(computed(()=>{
            return store.state.role
        }))
        const user = ref(computed(()=>{
            return store.state.user
        }))

        const platform = ref(computed(()=>{
            return store.state.platform
        }))
        
        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const sorted_lids = ref(computed(() => {
            if (selectedSorted.value.name == "")return filter_by_search.value;
            if (selectedSorted.value.name == "תאריך") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.created_at.seconds*1000) >  new Date(b.created_at.seconds*1000)
                        ? 1
                        : new Date(b.created_at.seconds*1000)  > new Date(a.created_at.seconds*1000)
                        ? -1
                        : 0;
                    }
                    return new Date(a.created_at.seconds*1000) > new Date(b.created_at.seconds*1000) 
                    ? -1
                    : new Date(b.created_at.seconds*1000)  > new Date(a.created_at.seconds*1000)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סוכן") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.agent_name >  b.agent_name
                        ? 1
                        : b.agent_name  > a.agent_name
                        ? -1
                        : 0;
                    }
                    return a.agent_name > b.agent_name
                    ? -1
                    : b.agent_name  > a.agent_name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סטטוס") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.status >  b.status
                        ? 1
                        : b.status  > a.status
                        ? -1
                        : 0;
                    }
                    return a.status > b.status
                    ? -1
                    : b.status  > a.status
                    ? 1
                    : 0;
                });
            }
            
        }));

        const handle_new_lid = (lid) =>{
            const index = agents_names.value.findIndex(agent=>agent.agent_id==lid.agent_id)
            if(index!=-1) lid.agent_name = agents_names.value[index].agent_name
            lidim.value.push(lid)
            new_lid.value = null
            show_new_lid.value = false
            state.value = null
        }

        const filter_by_search = ref(computed(()=>{
            return lidim.value.filter(lid=>{
                if(new Date(lid.created_at.seconds*1000).toLocaleDateString('he').includes(search.value)) return lid
                if(lid.business_name.includes(search.value)) return lid
                if(lid.manager_name.includes(search.value)) return lid
                if(lid.city.includes(search.value)) return lid
                if(lid.address.includes(search.value)) return lid
                if(lid.agent_name && lid.agent_name.includes(search.value)) return lid
                if(lid.phone.includes(search.value)) return lid
            })
        }))

        const agents_names = ref([])

        const agent = ref(null)
        const new_lid = ref(null)

        const show_new_lid = ref(false)
        const lidim = ref([])

        const handle_delete_lid = (lid)=>{
            const index = lidim.value.findIndex(_lid=>_lid.uid==lid.uid)
            index!=-1&&lidim.value.splice(index,1)
        }

        const handle_create_lid_with_previous_data = (previous_lid)=>{
            new_lid.value = previous_lid
            show_new_lid.value = true
        }
        const handle_create_lid = (_agent)=>{
            agent.value = _agent
            show_new_lid.value = true
        }
        const handle_open_lid = async(lid)=>{
             const { value: input } =  await Swal.fire({
                title: 'רשום את צורת הטיפול',
                input: 'text',
                inputPlaceholder: 'צורת הטיפול',
                showCancelButton: true,
                confirmButtonText: "המשך",
                cancelButtonText: "סגור", 
                })

                if (input) {
                    await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
                    .doc(lid.uid).set({
                        status:'pending',
                        pending_string:input,
                        pending_date:new Date()
                    },{merge:true})
                    
                    const index = lidim.value.findIndex(_lid=>_lid.uid==lid.uid)
                    if(index!=-1){
                        lidim.value[index].status = 'pending'
                        lidim.value[index].pending_string = input
                    }
                }
        }
        const handle_close_lid = async(lid)=>{
            const { value: input } = await Swal.fire({
                title: 'ע"מ לאשר ליד זה עליך לבחור אחת מהאופציות הבאות:',
                input: 'select',
                inputOptions: {
                      'נפתחה הזמנה ללקוח ובוצעה הזמנה':'נפתחה הזמנה ללקוח ובוצעה הזמנה',
                      'נפתח לקוח ולא בוצע הזמנה':'נפתח לקוח ולא בוצעה הזמנה',
                      'הלקוח לא מתאים ולא נפתח באישור ניר':'הלקוח לא מתאים ולא נפתח באישור ניר',
                      'לא רלוונטי':'לא רלוונטי',
                },
                inputPlaceholder: 'בחירה',
                showCancelButton: true,
                confirmButtonText: "המשך",
                cancelButtonText: "סגור", 
            })

                if (input && input!='לא רלוונטי') {
                    await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
                    .doc(lid.uid).set({
                        status:'close',
                        close_string:input,
                        close_date:new Date()
                    },{merge:true})

                    const index = lidim.value.findIndex(_lid=>_lid.uid==lid.uid)
                    index!=-1 &&  lidim.value.splice(index,1)
                }
                if(input && input=='לא רלוונטי'){
                    const { value: input } =  await Swal.fire({
                    title: 'רשום סיבת אי רלוונטיות',
                    input: 'text',
                    inputPlaceholder: 'הסיבה היא...',
                    showCancelButton: true,
                    confirmButtonText: "המשך",
                    cancelButtonText: "סגור", 
                    })

                    if (input) {
                        await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
                        .doc(lid.uid).set({
                            status:'e-relevant',
                            e_relevant_string:input,
                            canceled_date:new Date()
                        },{merge:true})
                        
                        const index = lidim.value.findIndex(_lid=>_lid.uid==lid.uid)
                        index!=-1 && lidim.value.splice(index,1)

                    }
                }
        }
        const handle_cancele_lid = async(lid)=>{
            const { value: input } =  await Swal.fire({
                title: 'רשום סיבת ביטול',
                input: 'text',
                inputPlaceholder: 'סיבת ביטול',
                showCancelButton: true,
                confirmButtonText: "המשך",
                cancelButtonText: "סגור", 
                })

                if (input) {
                    await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
                    .doc(lid.uid).set({
                        status:'canceled',
                        canceled_string:input,
                        canceled_date:new Date()
                    },{merge:true})
                    
                    const index = lidim.value.findIndex(_lid=>_lid.uid==lid.uid)
                    index!=-1 &&  lidim.value.splice(index,1)

                }
        }
        const init = async()=>{
            store.state.loader = true
          
            if(user.value && role.value){
                if(role.value>=99){
                    await get_all_lids_for_admin()
                }
                if(role.value==50 && user.value.agent){

                    await get_lids_for_agent()
                }
                await get_agents_names()
                store.state.loader = false
            }else{
                setTimeout(() => {
                    init()
                }, 200);
            }

        }
        const get_all_lids_for_admin = async()=>{
            const docs= await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
            .where('status','in',['open','pending']).orderBy('created_at','asc').get()
            lidim.value = docs.docs.map(doc=>doc.data())
        }

        const get_lids_for_agent = async()=>{
            const docs= await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
            .where('status','in',['open','pending']).where('agent_id','==',user.value.agent).orderBy('created_at','asc').get()
            lidim.value = docs.docs.map(doc=>doc.data())
        }

        const get_agents_names = async()=>{
            const docs = await projectFirestore.collection('Agents').get()
            const res = docs.docs.map(doc=>doc.data()).filter(agent=>agent.uid)
            .map((agent)=>({agent_name:agent.name,agent_id:agent.id}))
            agents_names.value = res

            agents_names.value.forEach(agent=>{
                lidim.value.filter(lid=>lid.agent_id == agent.agent_id)
                .forEach(lid=>lid.agent_name = agent.agent_name)
            })
        }

        init()

        return{
            lidim,
            show_new_lid,
            handle_new_lid,
            init,
            handle_close_lid,
            handle_cancele_lid,
            handle_open_lid,
            handle_create_lid,
            handle_create_lid_with_previous_data,
            role,
            platform,
            agent,
            state,
            new_lid,
            agents_names,
            search,
            filter_by_search,
            handleSortTable,
            selectedSorted,
            sorted_lids,
            lids_status_options,
            filter_by,
            filter_by_status,
            handle_delete_lid,

        }
    }
}
</script>

<style scoped>
    .lidim{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 5px;
    }
    .lidim-wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        padding: 5px 0;
        color: #fff;
    }
    .lidim-wrapper-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: var(--secondary);
        border-radius: 5px;
    }
    .lidim-wrapper-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    
    .lidim-wrapper-content{
        width: 100%;
        height: calc(100% - 100px);
        overflow-y: auto;
        
    }
    .lidim-wrapper-content-filter-bar{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .lidim-wrapper-content-table{
        width: 100%;
        height: calc(100% - 50px);
        overflow:hidden;
        overflow-y: auto;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 1200px){
        table th {
            position: static;
        }
    }
    .mobile-table{
        margin-bottom: 5px;
    }
    .options{
        display: flex;
        justify-content: space-between;
    }
</style>